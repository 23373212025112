@import "../core/misc";

@keyframes spin{
  0%{
      transform:rotate(0)
  }
  100%{
      transform:rotate(360deg)
  }
}

.toolbar-settings {
  position: sticky;
  top: 0;
  z-index: 999999999;
  opacity: 1;
  background: white;
}

.mat-form-field, .mat-select-content, .mat-select, .mat-option {
  font-size: $font-size-sm !important;
  width: 100%;
}

.mat-form-field-infix {
  border-top-width: 10px;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1003;
  background: transparent;
  overflow: hidden;
  z-index: 666666666666;
}

.spinner div:first-child {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  box-shadow: 0 3px 3px 0 rgba(255,56,106,1);
  transform: translate3d(0,0,0);
  animation: spin 2s linear infinite
}

.spinner div:first-child:after,.spinner div:first-child:before {
  content: '';
  position: absolute;
  border-radius: 50%
}

.spinner div:first-child:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  box-shadow: 0 3px 3px 0 rgb(255, 228, 32);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite
}

.spinner div:first-child:after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  box-shadow: 0 3px 3px 0 rgba(61, 175, 255,1);
  animation: spin 1.5s linear infinite
}

a {
  color: $link-color;
  &:hover,
  &:focus {
    color: darken($link-color, 5%);
    text-decoration: none;
  }

  &.text-info {
    &:hover, &:focus {
      color: darken($brand-info, 5%);
    }
  }

  & .material-icons {
    vertical-align: middle;
  }
}

a[data-toggle="collapse"][aria-expanded="true"] .caret,
.dropdown.open .caret,
.dropup.open .caret,
.btn-group.bootstrap-select.open .caret {
  @include rotate-180();
}

.caret,
.bootstrap-tagsinput .tag,
.sidebar a,
.bootstrap-tagsinput [data-role="remove"] {
  @include transition-all($fast-transition-time, $transition-ease-in);
}
