// @use '@angular/material' as mat;

// Hide the Clear button
// .drp-btn.flat.link {
//   display: none;
// }

// Hide the Chevron
.drp-flyout.tooltipChevron:before, .drp-flyout.tooltipChevron:after {
  content: initial !important;
}

// For pairing with a Material Input Field, needs to hide the Picker Input and move up the Picker Drop-down Calendar
.drp-flyout {
  margin-top: -60px;
  overflow: auto;
  max-height: 75vh;
}

.drp-wrapper input {
  display: none;
}

// Match font with Mat form fields
daterangepicker {
  font-size: 0.95rem !important;
}
