@use '@angular/material' as mat;

.wrapper {
  width: 100vw;
  height: 100vh;
}

.sidebar {
  z-index: 2;
  width: $side-bar-width;
  padding: 0;
  @include shadow-big();
  .logo-img {
    @extend .animation-transition-general;
    max-width: 175px;
    max-height: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .small-logo-img {
    @extend .animation-transition-general;
    max-height: 25px;
    max-width: 50px;
    margin: 8px;
  }
  .caret {
    display: inline-block;
    height: 0;
    width: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  .sidebar-wrapper {
    position: relative;
    width: $side-bar-width;
    z-index: 4;
    .dropdown .dropdown-backdrop {
      display: none !important;
    }
    .navbar-form {
      border: none;
      box-shadow: none;
      .input-group {
        font-size: 1.7em;
        height: 36px;
        width: 78%;
        padding-left: 17px;
      }
    }
    > .nav,
    .user .user-info {
      [data-toggle="collapse"] ~ div > ul > li > a {
        span {
          display: inline-block;
          @extend .animation-transition-general;
        }
        .sidebar-normal {
          margin: 0;
          position: relative;
          transform: translateX(0px);
          opacity: 1;
          white-space: nowrap;
          display: block;
        }
        .sidebar-mini {
          text-transform: uppercase;
          width: 30px;
          margin-right: 15px;
          text-align: center;
          letter-spacing: 1px;
          position: relative;
          float: left;
          display: inherit;
          .sidebar-mini-icons {
            font-size: 20px;
          }
        }
        i {
          font-size: 17px;
          line-height: 20px;
          width: 26px;
        }
      }
    }
  }
  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;
    img {
      height: 60px;
      width: 60px;
    }
  }
  .nav-content {
    height: calc(100vh - 280px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .nav {
    padding-top: 15px;
    display: block;
    .caret {
      margin-top: 13px;
      position: absolute;
      right: 6px;
    }
    li {
      &:first-child > a {
        margin: 0 15px;
      }
      &:hover:not(.active) > a,
      & .dropdown-menu a:hover,
      & .dropdown-menu a:focus {
        background-color: rgba($brand-background, 0.2);
        box-shadow: none;
      }
      &.active > [data-toggle="collapse"] {
        i {
          color: $brand-disabled;
        }
      }
      &.active > a,
      &.active > a i {
        color: mat.get-color-from-palette($app-foreground, base) !important;
      }
      &.separator {
        margin: 15px 0;
        &:after {
          width: calc(100% - 30px);
          content: "";
          position: absolute;
          height: 1px;
          left: 15px;
          background-color: rgba(180, 180, 180, .3);
        }
        & + li {
          margin-top: 31px;
        }
      }
    }
    p {
      margin: 0;
      line-height: 30px;
      font-size: 14px;
      position: relative;
      display: block;
      height: auto;
      white-space: nowrap;
      @extend .animation-transition-general;
    }
    i {
      font-size: 24px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
  }
  .nav li a,
  .nav li .dropdown-menu a {
    margin: 10px 15px 0;
    border-radius: $border-radius-base;
    color: white;
    padding-left: 7px;
    padding-right: 8px;
    text-transform: inherit;
    font-size: $font-paragraph - 1;
  }
  .sidebar-background {
    @extend .animation-transition-general;
    position: absolute;
    display: block;
    height: 100%;
    width: $side-bar-width;
    background-image: url('../../img/sidebar-1.jpg');
    background-size: cover;
    background-position: center center;
    z-index: 1;
    &:after {
      @extend .animation-transition-general;
      position: absolute;
      display: block;
      height: 100%;
      width: $side-bar-width;
      content: "";
      background: $brand-background;
      opacity: .93;
      z-index: 3;
    }
  }
  .logo {
    padding: 10px 0;
    margin: 0;
    display: block;
    position: relative;
    z-index: 4;
    .logo-mini,
    a.logo-normal {
      @extend .animation-transition-general;
    }
    div.logo-mini {
      opacity: 1;
      margin-left: 25px;
    }
    a.logo-normal {
      display: block;
      opacity: 1;
      @include transform-translate-x(0px);
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background-color: rgba(180, 180, 180, .3);
    }
    .simple-text {
      color: white;
      text-transform: uppercase;
      vertical-align: middle;
      display: inline-block;
      font-size: 18px;
      white-space: nowrap;
      font-weight: $font-weight-default;
      line-height: 25px;
      overflow: hidden;
    }
    .app-title {
      color: white;
      font-size: 1.2rem;
      text-transform: initial;
      padding-top: 0;
      padding-bottom: 0;
    }
    .app-version {
      color: white;
      font-size: 0.8em;
      text-transform: initial;
      padding-top: 0;
      padding-bottom: 0;
    }
    a.app-version {
      &:hover {
        color: $brand-text;
      }
    }
  }
  .user {
    padding-bottom: 10px;
    margin: 10px auto 0;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background-color: rgba(180, 180, 180, .3);
    }
    .photo {
      overflow: hidden;
      float: left;
      z-index: 5;
      margin-right: 11px;
      border-radius: 50%;
      margin-left: 17px;
      @include shadow-big();
      @extend .animation-transition-general;
      img {
        height: 3.5em;
        width: 3.5em;
      }
    }
    div {
      color: white;
    }
    a {
      color: white;
      padding: $padding-base-vertical $padding-large-vertical;
      white-space: nowrap;
      cursor: pointer;
    }
    .user-info {
      > div {
        display: block;
        line-height: 18px;
        font-size: 14px;
        > span {
          @extend .animation-transition-general;
          display: block;
          position: relative;
          opacity: 1;
        }
      }
      .edit {
        position: absolute;
        top: 0px;
        right: 15px;
      }
    }
  }
  .sidebar-background:after {
    background: $brand-primary;
    opacity: .7;
  }
  li.active > a {
    background-color: $brand-background;
  }
  .user,
  .logo,
  .nav li.separator {
    &:after {
      background-color: white;
    }
  }
  .nav li .dropdown-menu .dropdown-item {
    color: $brand-text;
  }
  .nav {
    li:hover:not(.active) > a,
    li.active > [data-toggle="collapse"] {
      background-color: rgba($brand-background, 0.2);
    }
  }
  &[data-image]:after,
  &.has-image:after {
    opacity: .77;
  }
}

.off-canvas-sidebar .navbar-collapse {
  .nav {
    > li > a,
    > li > a:hover {
      color: $brand-background;
      margin: 0 15px;
    }
    > li > a:focus {
      background: rgba($brand-text, 0.4);
    }
    > li > a:hover {
      background: rgba($brand-text, 0.2);
    }
  }
}

.main-panel {
  width: $sidebar-width;
  height: 100%;
  padding: 15px;
  @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  .main-content {
    content: "";
    height: 100%;
    width: 100%;
    background: $brand-background;
    background-size: cover;
    will-change: transform;
    z-index: 0;
  }
  .footer {
    border-top: 1px solid #e7e7e7;
  }
  .full-content {
    flex: 1;
    height: 100%
  }
  .navbar {
    position: relative !important;
    padding: 0 !important;
    margin-bottom: 0;
  }
  .header {
    margin-bottom: 30px;
    .title {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.perfect-scrollbar-on {
  .sidebar,
  .main-panel {
    height: 100%;
    max-height: 100%;
  }
}

.sidebar,
.main-panel,
.sidebar-wrapper {
  -webkit-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: .2s, .2s, .35s;
  transition-duration: .2s, .2s, .35s;
  -webkit-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.visible-on-sidebar-regular {
  display: inline-block !important;
}

.visible-on-sidebar-mini {
  display: none !important;
}

@media (min-width: 991px) {
  .sidebar-mini {
    .visible-on-sidebar-regular {
      display: none !important;
    }
    .visible-on-sidebar-mini {
      display: inline-block !important;
    }
    .sidebar,
    .sidebar .sidebar-wrapper {
      width: $side-bar-mini-width;
    }
    .sidebar {
      display: block;
      font-weight: 200;
      z-index: 1999;
      .logo {
        a.logo-normal {
          //display: block;
          opacity: 0;
          @include transform-translate-x(-25px);
        }
      }
      .sidebar-background {
        width: $side-bar-mini-width;
        &:after {
          width: $side-bar-mini-width;
        }
      }
      .sidebar-wrapper {
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user .user-info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user .user-info > a > span,
        > .nav li > a p {
          @include transform-translate-x(-25px);
          opacity: 0;
        }
      }
    }
  }
}

.hide-sidebar {
  .sidebar {
    @extend .animation-transition-general;
    @include transform-translate-x(-$side-bar-width);
  }
  .main-panel {
    width: 100%;
  }
  &.sidebar-mini {
    .sidebar {
      @extend .animation-transition-general;
      @include transform-translate-x(-$side-bar-mini-width);
    }
  }
}

.animation {
  @extend .animation-transition-general;
}
