// Modals
$side-bar-mini-width: 80px;
$side-bar-width: 275px;

$modal-content-xs-box-shadow: $bmd-shadow-24dp !default;

$modal-backdrop-opacity: .26 !default; // .5

$transition-ease-in: ease-in !default;
$transition-ease-out: ease-out !default;
$ultra-fast-transition-time: 60ms !default;
$navbar-padding-a: 10px 15px;
$padding-zero: 0px !default;
$sidebar-width: calc(100% - $side-bar-width) !default;
$sidebar-mini-width: calc(100% - $side-bar-mini-width) !default;
$topbar-back: topbar-back !default;
$bottombar-back: bottombar-back !default;
$topbar-x: topbar-x !default;
$bottombar-x: bottombar-x !default;
$margin-bottom: 0 0 10px 0 !default;
$margin-base-vertical: 15px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  3xl: 2000px
);
